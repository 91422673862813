import { observer } from "mobx-react";
import React from "react";
import { Store } from "../../store";
import './TriesList.css';

type TriesListProps = {
    store: Store;
}

@observer
export class TriesList extends React.Component<TriesListProps> {
    render() {
        return (
            <div className="Game-wrapper-tries-list">
                {this.props.store.gameEnded ? <div className="Game-wrapper-game-ended">Game has ended, your number ({this.props.store.userNumber.get()}) is equal to generated number ({this.props.store.numberToGuess.get()})</div> : <></>}
                {this.props.store.orderedTriesList.map((x, i) => {
                    return (
                        <div className="Game-wrapper-tries-member" key={`key-${x.userNumber}-${x.bulls}-${x.cows}-${i}`}>
                            Attepmt {x.attemptNumber} on date: {this.getAttemptDate(x.dateGuessed)} Number: {x.userNumber}, Bulls: {x.bulls}, Cows: {x.cows}
                        </div>
                    )
                })}
            </div>
        )
    }

    private getAttemptDate = (date: Date): string => {
        const day = date.getDate();
        const parsedMonth = date.getMonth() + 1;
        const month = parsedMonth < 10 ? `0${parsedMonth}` : parsedMonth;
        const year = date.getFullYear();
        const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        const minues = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
        return `${day}.${month}.${year} ${hours}:${minues}:${seconds}`;
    }
}