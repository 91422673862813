import React from 'react';
import '../App.css';
import { Header, Menu } from '../shared';

type IndexRouteProps = {};

export class IndexRoute extends React.Component<IndexRouteProps> {
    render() {
        return (
            <div className="App-page">
                <Header />
                <Menu />
                <div className="App-elements-container">
                    <div className="App-message">Please select an option from the menu above.</div>
                </div>
            </div>
        )
    }
}