import { BullsAndCowsResponse, IGameLogic } from "../types";

export class GameLogic implements IGameLogic {
    getBullsAndCows(secret: string, guess: string): BullsAndCowsResponse {
        let n = secret.length;
        let bulls = 0;
        let cows = 0;
        
        let splittedSecret = secret.split('');
        let splittedGuess = guess.split('');

        for (let i = 0; i < n; i++) {
            if (splittedSecret[i] === splittedGuess[i]) {
                splittedSecret[i] = '';
                splittedGuess[i] = '';
                bulls++;
            }        
        }

        for (let i = 0; i < n; i++) {
            if (splittedSecret[i] !== '') {
                let j = splittedGuess.indexOf(splittedSecret[i]);
                if (j > -1) {
                    splittedSecret[i] = '';
                    splittedGuess[j] = '';                
                    cows++;
                }
            }
        }

        return {
            bulls: bulls.toString(),
            cows: cows.toString()
        }
    }
}