import { observer } from "mobx-react";
import React from "react";
import { Store } from "../../store";

type ErrorMessageProps = {
    store: Store;
}

@observer
export class ErrorMessage extends React.Component<ErrorMessageProps> {
    render() {
        return !this.props.store.userInputValid.get() ? (
            <div className="Game-wrapper-message">Please enter exactly 4 digits.</div>
        ) : <></>;
    }
}