import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { Store } from './store';
import { IGameLogic, IInputValidator, INumberGenerator } from './types';
import { NumberGenerator, InputValidator, GameLogic } from './services';

const numberGenerator: INumberGenerator = new NumberGenerator();
const inputValidator: IInputValidator = new InputValidator();
const gameLogic: IGameLogic = new GameLogic();
const store = new Store();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App store={store} numberGenerator={numberGenerator} inputValidator={inputValidator} gameLogic={gameLogic} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
