import React from 'react';
import '../App.css';
import { Header, Menu } from '../shared';
import { Store } from '../store';
import { IGameLogic, IInputValidator, INumberGenerator } from '../types';
import { SingleplayerLogic } from './singleplayer';

type SingleplayerRouteProps = {
    store: Store;
    numberGenerator: INumberGenerator;
    inputValidator: IInputValidator;
    gameLogic: IGameLogic;
};

export class SingleplayerRoute extends React.Component<SingleplayerRouteProps> {
    render() {
        return (
            <div className='App-page'>
                <Header />
                <Menu />
                <SingleplayerLogic
                    store={this.props.store}
                    numberGenerator={this.props.numberGenerator}
                    inputValidator={this.props.inputValidator}
                    gameLogic={this.props.gameLogic} />
            </div>
        )
    }
}