import React from "react";
import { Link } from "react-router-dom";
import './Menu.css';

export class Menu extends React.Component {
    private readonly routes = Object.freeze({
        home: '/',
        singleplayer: '/singleplayer',
        multiplayer: '/multiplayer'
    });
    render() {
        return (
            <div className="App-menu">
                <Link className={this.getClassList(this.routes.home)} to={this.routes.home}>Home</Link>
                <Link className={this.getClassList(this.routes.singleplayer)} to={this.routes.singleplayer}>Single player</Link>
                <Link className={this.getClassList(this.routes.multiplayer)} to={this.routes.multiplayer}>Multi player (still in development)</Link>
            </div>
        )
    }

    private getClassList(path: string) {
        return window.location.pathname === path ? `App-menu-link App-menu-link-selected` : `App-menu-link`;
    }
}