import { IInputValidator } from "../types";

export class InputValidator implements IInputValidator {
    validate(data: string): boolean {
        return data.length === 4 && this.checkInputForDigits(data) && this.checkIfDigitsArePresentOnce(data);
    }

    private checkInputForDigits = (data: string) => {
        return data.split('').every(x => !Number.isNaN(parseInt(x)));
    }

    private checkIfDigitsArePresentOnce = (data: string) => {
        const numbers = data.split('');
        return numbers.every(x => numbers.filter(n => x === n).length === 1);
    }
}