import React from 'react';
import './App.css';
import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";
import { IndexRoute, MultiplayerRoute, SingleplayerRoute } from './routes';
import { Store } from './store';
import { IGameLogic, IInputValidator, INumberGenerator } from './types';

type AppProps = {
  store: Store;
  numberGenerator: INumberGenerator;
  inputValidator: IInputValidator;
  gameLogic: IGameLogic;
};

export class App extends React.Component<AppProps> {
  render() {
    return (
      <div className="App">
        <div className="App-body">
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<IndexRoute />} />
              <Route path='/singleplayer' element={<SingleplayerRoute
                store={this.props.store}
                numberGenerator={this.props.numberGenerator}
                inputValidator={this.props.inputValidator}
                gameLogic={this.props.gameLogic} />} />
              <Route path='/multiplayer' element={<MultiplayerRoute
                store={this.props.store}
                numberGenerator={this.props.numberGenerator} />} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    );
  }
}