import { action, computed, IObservableArray, IObservableValue, observable } from "mobx";
import { BullsAndCowsResponse, Guess } from "../types";

export class Store {
    @observable
    numberToGuess: IObservableValue<string>;

    @observable
    userNumber: IObservableValue<string>;

    @observable
    triesList: IObservableArray<Guess>;

    @observable
    userInputValid: IObservableValue<boolean>;

    @observable
    checkButtonClicked: IObservableValue<boolean>;

    @computed
    get gameEnded(): boolean {
        const userNumber = this.userNumber.get();
        const numberToGuess = this.numberToGuess.get();
        return this.checkButtonClicked.get() && userNumber.length === 4 && userNumber === numberToGuess;
    }

    @computed
    get orderedTriesList() {
        return this.triesList.slice().sort((a, b) => b.dateGuessed.getTime() - a.dateGuessed.getTime());
    }

    constructor() {
        this.numberToGuess = observable.box('');
        this.triesList = observable.array();
        this.userNumber = observable.box('');
        this.userInputValid = observable.box(true);
        this.checkButtonClicked = observable.box(false);
    }

    @action
    resetGame = () => {
        this.numberToGuess.set('');
        this.triesList.clear();
        this.userNumber.set('');
        this.userInputValid.set(true);
        this.checkButtonClicked.set(false);
    }

    @action
    setNumberToGuess = (number: string) => {
        this.numberToGuess.set(number);
    }

    @action
    setUserNumber = (number: string) => {
        this.userNumber.set(number);
    }

    @action
    addTry = (bullsAndCows: BullsAndCowsResponse) => {
        this.triesList.push({
            userNumber: this.userNumber.get().toString(),
            bulls: bullsAndCows.bulls,
            cows: bullsAndCows.cows,
            dateGuessed: new Date(),
            attemptNumber: (this.triesList.length + 1).toString()
        });
    }

    @action
    checkNumber = (number: string) => {
        this.userNumber.set(number);
    }

    @action
    setUserInputValidation = (validationResult: boolean) => {
        this.userInputValid.set(validationResult);
    }

    @action
    clickCheckButton = (isClicked: boolean) => {
        this.checkButtonClicked.set(isClicked);
    }
}