import React from 'react';
import '../App.css';
import { Header, Menu } from '../shared';
import { Store } from '../store';
import { INumberGenerator } from '../types';

type MultiplayerRouteProps = {
    store: Store;
    numberGenerator: INumberGenerator;
};

export class MultiplayerRoute extends React.Component<MultiplayerRouteProps> {
    render() {
        return (
            <div className="App-page">
                <Header />
                <Menu />
                <div className="App-message">Multiplayer functionality is still in development.</div>
            </div>
        )
    }
}