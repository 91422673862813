import { INumberGenerator } from "../types";

export class NumberGenerator implements INumberGenerator {
    generate() {
        const digits = [
            this.getRandomInt()
        ];
        let secondDigit = this.getRandomInt();
        while (digits.includes(secondDigit)) {
            secondDigit = this.getRandomInt();
        }
        digits.push(secondDigit);
        let thirdDigit = this.getRandomInt();
        while (digits.includes(thirdDigit)) {
            thirdDigit = this.getRandomInt();
        }
        digits.push(thirdDigit);
        let fourthDigit = this.getRandomInt();
        while (digits.includes(fourthDigit)) {
            fourthDigit = this.getRandomInt();
        }
        digits.push(fourthDigit);
        return parseInt(digits.join(''));
    }

    private getRandomInt() {
        return Math.floor(Math.random() * 9) + 1; // Range between 1 and 9 inclusive
    }
}