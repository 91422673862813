import React from 'react';
import '../../App.css';
import './SingleplayerLogic.css';
import { Store } from '../../store';
import { IGameLogic, IInputValidator, INumberGenerator } from '../../types';
import { observer } from 'mobx-react';
import { ErrorMessage } from './ErrorMessage';
import { TriesList } from './TriesList';

type SingleplayerLogicProps = {
    store: Store;
    numberGenerator: INumberGenerator;
    inputValidator: IInputValidator;
    gameLogic: IGameLogic;
};

@observer
export class SingleplayerLogic extends React.Component<SingleplayerLogicProps> {
    componentDidMount(): void {
        this.props.store.setNumberToGuess(this.props.numberGenerator.generate().toString());
    }
    componentWillUnmount(): void {
        this.props.store.resetGame();
    }
    render() {
        return (
            <div className="App-game">
                <div className="Game-wrapper">
                    <div className="Game-wrapper-number-input"><input type="text" className={!this.props.store.userInputValid.get() ? 'Input-Error' : ''} maxLength={4} minLength={4} value={this.props.store.userNumber.get()} onChange={this.onUserInput} placeholder={'Input 4 digit number'} /></div>
                    <div className="Game-wrapper-number-check"><button onClick={this.checkNumber} disabled={this.props.store.gameEnded}>Check</button><button onClick={this.resetGame}>Reset game</button></div>
                    <ErrorMessage store={this.props.store} />
                    <div className="Game-wrapper-number-of-tries">{this.props.store.triesList.length ? `Attempts: ${this.props.store.triesList.length}` : ''}</div>
                    <TriesList store={this.props.store} />
                </div>
            </div>
        )
    }

    private onUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.store.checkButtonClicked.get()) {
            this.props.store.clickCheckButton(false);
        }
        this.props.store.setUserNumber(event.target.value);
    }

    private checkNumber = (event: React.MouseEvent<HTMLButtonElement>) => {
        const validationResult = this.props.inputValidator.validate(this.props.store.userNumber.get().toString());
        this.props.store.setUserInputValidation(validationResult);
        if (!validationResult) {
            setTimeout(() => {
                this.props.store.setUserInputValidation(true);
            }, 2000);
            return;
        }
        this.props.store.addTry(this.props.gameLogic.getBullsAndCows(this.props.store.numberToGuess.get(), this.props.store.userNumber.get()));
        this.props.store.checkNumber(this.props.store.userNumber.get());
        this.props.store.clickCheckButton(true);
    }

    private resetGame = (_: React.MouseEvent<HTMLButtonElement>) => {
        this.props.store.resetGame();
        this.props.store.setNumberToGuess(this.props.numberGenerator.generate().toString());
    }
}